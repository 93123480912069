import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { trimValues } from '@/application/utils/trim.js'
import { sendPassword } from '@/application/services/auth.js'
import { setCookie } from '@/application/utils/cookie.js'
import { getLocalizedServerError } from '@/application/utils/localization'
import { trackLogin } from '@/application/utils/tracking-scripts'
import { useReCaptcha } from 'vue-recaptcha-v3'

export function useLoginPasswordForm(additionalData) {
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
  const i18n = useI18n()
  const store = useStore()
  const router = useRouter()
  const isDisabled = ref(false)
  const errorMessage = ref('')
  const passwordFormSchema = computed(() => [
    {
      label: i18n.t('password'),
      name: 'password',
      as: 'input',
      type: 'password',
      rules: 'required|min:8|alpha_num',
      cols: 'col-12',
      inputClass: 'form-control',
    },
  ])

  const passwordSubmitButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('logIn'),
  }))

  const submitPassword = async(data) => {
    isDisabled.value = true
    data.contact = additionalData.contact
    data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const formatted = trimValues(data)

    await recaptchaLoaded()
    const token = await executeRecaptcha('login_enter_password')
    formatted.recaptcha_token = token

    sendPassword(formatted)
      .then(({ data }) => {
        setCookie(data)
        isDisabled.value = false
        store.commit('changeAuthStatus', data.session_token)
        trackLogin()
        router.push({ name: 'dashboard' })
      })
      .catch(err => {
        errorMessage.value = getLocalizedServerError(i18n, 'forms.signUp.errors.', err.response)
        isDisabled.value = false
      })
  }

  return {
    passwordFormSchema,
    submitPassword,
    passwordSubmitButton,
    errorMessage,
    isDisabled,
  }
}
