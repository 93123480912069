<template>
  <h3 class="mb-4 text-center">{{ $t('Login') }}</h3>
  <div class="form-group mb-3">
    <template v-if="contactStep">
      <DynamicForm
        :schema="formSchema"
        :initial-field-value="initContactForm"
        :submit-always-enabled="true"
        :button-data="submitButton"
        :is-disabled="contactDisabled"
        form-class="justify-content-center"
        @submit="submit"
      >
        <template v-slot:error>
          <div class="server-error-message content mt-3" v-if="contactErrorMessage.length">
            {{ contactErrorMessage }}
            <button @click.prevent="contactErrorMessage = ''" class="feather icon-x button"></button>
          </div>
        </template>
      </DynamicForm>
      <p class="mb-4 text-muted text-center">{{$t('signupProposal')}}
        <router-link :to="{ name: 'sign-up' }" class="license-preview">{{$t('signup')}}</router-link>
      </p>
      <p class="mb-4 text-muted text-center">
        <router-link :to="{ name: 'restore-password' }" class="license-preview">{{$t('restorePasswordProposal')}}</router-link>
      </p>
    </template>
    <p class="mb-3 text-center" v-if="otpCodeStep">{{ $t('codeDescription') }}</p>
    <DynamicForm
      v-if="otpCodeStep"
      :schema="codeFormSchema"
      :button-data="codeSubmitButton"
      :is-disabled="codeDisabled"
      form-class="justify-content-center"
      @submit="submitCode"
    >
      <template v-slot:error>
        <div class="server-error-message content mt-3" v-if="codeErrorMessage.length">
          {{ codeErrorMessage }}
          <button @click.prevent="codeErrorMessage = ''" class="feather icon-x button"></button>
        </div>
      </template>
    </DynamicForm>
    <p class="mb-3 text-center" v-if="passwordStep">{{ $t('passwordDescription') }}</p>
    <DynamicForm
      v-if="passwordStep"
      :schema="passwordFormSchema"
      :button-data="passwordSubmitButton"
      :is-disabled="passwordDisabled"
      form-class="justify-content-center"
      @submit="submitPassword"
    >
      <template v-slot:error>
        <div class="server-error-message content mt-3" v-if="passwordErrorMessage.length">
          {{ passwordErrorMessage }}
          <button @click.prevent="passwordErrorMessage = ''" class="feather icon-x button"></button>
        </div>
      </template>
    </DynamicForm>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import DynamicForm from './DynamicForm.vue'
import { useLoginContactForm } from '@/application/composables/loginContactForm.js'
import { useLoginCodeForm } from '@/application/composables/loginCodeForm.js'
import { useLoginPasswordForm } from '@/application/composables/loginPasswordForm.js'

export default {
  name: 'SignInForm',
  components: {
    DynamicForm,
  },
  setup() {
    const initContactForm = ref({})

    const {
      formSchema,
      submitButton,
      submit,
      codeRequestData,
      isDisabled: contactDisabled,
      authType,
      errorMessage: contactErrorMessage,
      AUTH_OTP_TYPE,
      AUTH_PASSWORD_TYPE,
      queryEmail,
    } = useLoginContactForm()

    const {
      codeFormSchema,
      codeSubmitButton,
      submitCode,
      errorMessage: codeErrorMessage,
      isDisabled: codeDisabled,
    } = useLoginCodeForm(codeRequestData.value)

    const {
      passwordFormSchema,
      submitPassword,
      passwordSubmitButton,
      isDisabled: passwordDisabled,
      errorMessage: passwordErrorMessage,
    } = useLoginPasswordForm(codeRequestData.value)

    const contactStep = computed(() => authType.value === '')
    const otpCodeStep = computed(() => authType.value === AUTH_OTP_TYPE)
    const passwordStep = computed(() => authType.value === AUTH_PASSWORD_TYPE)

    onMounted(() => {
      if (queryEmail) {
        initContactForm.value = { contact: queryEmail }
      }
    })

    return {
      formSchema,
      codeFormSchema,
      submit,
      submitButton,
      codeSubmitButton,
      contactDisabled,
      codeDisabled,
      authType,
      submitCode,
      contactErrorMessage,
      codeErrorMessage,
      passwordFormSchema,
      submitPassword,
      passwordSubmitButton,
      passwordDisabled,
      passwordErrorMessage,
      contactStep,
      otpCodeStep,
      passwordStep,
      initContactForm,
    }
  },
}
</script>
